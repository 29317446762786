import { Icon } from '@coolfire/icons';
import { Button, Input } from '@coolfire/ui';
import type { MetaFunction } from '@remix-run/node';
import {
	unstable_defineAction as defineAction,
	unstable_defineLoader as defineLoader,
} from '@remix-run/node';
import {
	Form,
	Link,
	useActionData,
	useLoaderData,
	useLocation,
	useNavigate,
	useNavigation,
} from '@remix-run/react';
import { route } from 'routes-gen';
import { getServerFeatureFlags } from '~/domains/feature-flags.server';
import { config } from '~/server/services/config.server';

export const loader = defineLoader(async ({ context }) => {
	const { selfSignups } = await getServerFeatureFlags(context);
	return { selfSignups };
});

export const action = defineAction(async ({ request, response }) => {
	const formData = await request.clone().formData();
	const url = new URL(request.url);

	const redirectTo = url.searchParams.get('redirectTo');
	const res = await fetch(`${config.API_URL}/core/login`, {
		method: 'post',
		body: JSON.stringify({ email: formData.get('email'), password: formData.get('password') }),
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (res.ok) {
		const cookie = res.headers.get('Set-Cookie');
		if (cookie) {
			response.headers.append('Set-Cookie', cookie);
		}
		response.status = 302;
		response.headers.set('Location', redirectTo ?? '/');
		throw response;
	}

	if (res.status === 400) {
		const data = await res.json();
		const error =
			data &&
			typeof data === 'object' &&
			'error' in data &&
			data.error &&
			typeof data.error === 'object' &&
			'message' in data.error &&
			typeof data.error.message === 'string'
				? new Error(data.error.message)
				: new Error('Unknown error');
		error.name =
			data &&
			typeof data === 'object' &&
			'error' in data &&
			data.error &&
			typeof data.error === 'object' &&
			'name' in data.error &&
			typeof data.error.name === 'string'
				? data.error.name
				: 'Unknown error';

		switch (error.name) {
			case 'NotAuthorizedException': {
				response.status = 400;
				return {
					error: error.message,
				};
			}
			case 'UserNotConfirmedException': {
				const formData = await request.formData();
				const email = formData.get('email');

				if (typeof email !== 'string') {
					response.status = 400;
					return 'Email was not provided';
				}

				response.status = 302;
				response.headers.set(
					'Location',
					`${route('/confirm-signup')}?email=${encodeURIComponent(email)}`,
				);
				throw response;
			}
			default: {
				response.status = 500;
				return data;
			}
		}
	}
});

export function ErrorBoundary() {
	const navigate = useNavigate();
	const location = useLocation();
	const navigation = useNavigation();

	const isRefreshing =
		navigation.location?.pathname === location.pathname && navigation.state === 'loading';

	function refresh() {
		navigate('.', { replace: true });
	}

	return (
		<div className="flex flex-1 flex-col items-center justify-center gap-6">
			<div className="flex flex-col items-center justify-center gap-1">
				<Icon id="outline:sentiment-very-dissatisfied" className="h-20 w-20 text-blue-600" />
				<h1 className="pt-2 text-center text-2xl font-medium">Whoops!</h1>
				<div className="my-1 text-base font-medium leading-6">An unexpected error occurred</div>
			</div>
			<Button
				icon="outline:refresh"
				variant="secondary"
				onClick={refresh}
				isLoading={isRefreshing}
				disabled={isRefreshing}
			>
				Try Again
			</Button>
		</div>
	);
}

export const meta: MetaFunction = () => [{ title: 'Sign in to Core' }];

export default function Login() {
	const { selfSignups } = useLoaderData<typeof loader>();
	const navigation = useNavigation();
	const actionData = useActionData<typeof action>();

	const submitting = navigation.state === 'submitting';

	const error = actionData?.error;

	return (
		<>
			<header className="space-y-2 text-center">
				<h1 className="text-2xl font-bold leading-8">Sign In</h1>
				<p className="text-base font-normal leading-6 text-gray-500">
					Welcome back! Sign in to continue
				</p>
			</header>
			<Form className="mt-8 w-full max-w-md space-y-8" method="post">
				<div className="space-y-3">
					<Input label="Email" name="email" required type="email" placeholder="you@example.com" />
					<Input label="Password" name="password" required type="password" placeholder="Password" />
					{error && <p className="text-sm text-rose-500">{error}</p>}
				</div>
				<Button fullWidth type="submit" disabled={submitting} isLoading={submitting}>
					Sign In
				</Button>
			</Form>

			<Link
				to={route('/forgot-password')}
				className="mt-10 text-base font-normal leading-6 text-blue-600"
			>
				Forgot your password?
			</Link>

			{selfSignups && (
				<Link to={route('/signup')} className="mt-20 text-base font-normal leading-6 text-blue-600">
					Don't have an account? Sign Up
				</Link>
			)}
		</>
	);
}
